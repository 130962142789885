import * as React from 'react';

import { MainSection, ReadyToStartSection } from 'components/HomeSections';
import MainLayout from 'layouts/MainLayout';

import { Box, Container } from '@mui/material';
import { graphql, PageProps } from 'gatsby';
import { GenericTemplate, ReferencesTemplate } from 'templates';
import { sectionDarkStyle, sectionStyle } from 'utils';
import { Advertisement, DataSlider } from 'components';
import { AnimatedContainer } from 'components/containers';
import mainSectionImg from 'images/Starting-a-business-project-pana.svg';
import bgrGreenSmall from 'images/bgr/bgr-green-small.inline.png';

type NodeProps = {
  locale: string;
  order: number;
  template: string;
  slug: string;
  key: string;
  sum: string;
  title: string;
  subtitle: string;
  items: {
    type: string;
    data: any[];
  };
  img?: { src: { publicURL: string } };
  banner?: {
    text: string;
    contactButton: boolean;
    color: 'dark' | 'main';
  };
  darkBgr?: boolean;
};

const IndexPage = ({ data }: PageProps<any>) => {
  const offerItems = data.allHome.nodes.find((el: any) => el.key === 'offer-section')?.items.data;
  const metaTitle = data.allHome.nodes.find((el: any) => el.key === 'about-us-section')?.metaTitle;
  const metaDescription = data.allHome.nodes.find((el: any) => el.key === 'about-us-section')?.metaDescription;

  const setTemplate = (node: NodeProps) => {
    const bannerItems = [
      'FinTech',
      'Telco',
      'Banking',
      'Health',
      'Crypto',
      'POS Systems',
      'Blockchain',
      'Utility',
      'Smart City',
      'IoT'
    ];
    switch (node.template) {
      case 'generic':
        return (
          <React.Fragment key={node.title}>
            <Box sx={[{ py: { xs: 3, md: 4 }, my: { xs: 3, md: 4 } }, node.darkBgr ? sectionDarkStyle : sectionStyle]}>
              <Container maxWidth="lg" sx={{ position: 'relative' }}>
                <GenericTemplate data={node} />
              </Container>
            </Box>
            {node.key === 'why-us-section' && <DataSlider texts={bannerItems} />}
            {node.banner && (
              <AnimatedContainer>
                <Box sx={{ my: { xs: 3, md: 4 } }}>
                  <Advertisement
                    text={node.banner.text}
                    contactButton={node.banner.contactButton}
                    color={node.banner.color}
                  />
                </Box>
              </AnimatedContainer>
            )}
          </React.Fragment>
        );
      case 'references-template':
        return (
          <React.Fragment key={node.title}>
            <Box sx={{ backgroundColor: 'background.default', py: { xs: 3, md: 4 }, my: { xs: 3, md: 4 } }}>
              <Container maxWidth="lg">
                <ReferencesTemplate data={node} />
              </Container>
            </Box>
          </React.Fragment>
        );

      default:
        break;
    }
  };

  return (
    <MainLayout
      pageTitle="NextGen Software House"
      metaTitle={metaTitle}
      metaDescription={metaDescription}
      offerItems={offerItems}
    >
      <AnimatedContainer>
        <MainSection
          title="main-title"
          subtitle={{
            id: 'main-intro',
            values: {
              value1: <strong>competitors cry</strong>,
              value2: <strong>long-term investment</strong>
            }
          }}
          icon={mainSectionImg}
        />
      </AnimatedContainer>
      {data.allHome.nodes.map((el: any) => setTemplate(el))}

      <AnimatedContainer>
        <Box sx={{ background: `url(${bgrGreenSmall}) no-repeat center center / cover` }}>
          <Container maxWidth="lg" sx={{ pt: { xs: 6, md: 8 }, pb: 8 }}>
            <ReadyToStartSection
              title="Start making business with our software"
              subtitle="Do you find our offer interesting and wish to learn more? Contact us to arrange a  meeting!"
              light
            />
          </Container>
        </Box>
      </AnimatedContainer>
    </MainLayout>
  );
};

export const query = graphql`
  query ($locale: String) {
    allHome(filter: { locale: { eq: $locale } }, sort: { fields: order, order: ASC }) {
      nodes {
        id
        key
        order
        metaTitle
        metaDescription
        sum
        title
        subtitle
        template
        titleAlign
        img {
          src {
            publicURL
          }
        }
        items {
          type
          grid {
            xs
            md
          }
          data {
            key
            title
            text
            challenge
            solution
            result
            color
            icon {
              src {
                svg {
                  content
                }
              }
            }
            authorName
            authorDetails
            authorImg {
              src {
                publicURL
              }
            }
          }
        }
        banner {
          text
          contactButton
          color
        }
        darkBgr
      }
    }
  }
`;

export default IndexPage;
